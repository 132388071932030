import { Component } from '@angular/core';
import { Router } from '@angular/router'
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { AuthenticationService } from 'app/service/authentication/authentication.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  isLoading: boolean = false;
  isPasswordReset: boolean = false;
  email: string = "";
  errorMessage: string = "";
  password: string = "";
  repeatPassword: string = "";

  constructor(private authService: AuthenticationService, private router: Router) { }

  onSignIn() {
    if (this.email && this.password) {
      this.isLoading = true;

      this.authService.login(this.email, this.password).then((result) => {
        console.debug("Authentication success");
        window.location.href = "/";
      }).catch((err) => {
        if (err === "SET_NEW_PASSWORD") {
          this.isPasswordReset = true;
          this.errorMessage = "You must set a new password";
          this.password = "";
        } else {
          this.errorMessage = err.message || JSON.stringify(err);
          console.error(err);
        }

        this.isLoading = false;
      });
    }
  }

  setNewPassword() {
    if (this.email && this.password && this.repeatPassword) {
      this.isLoading = true;

      this.authService.handleNewPassword(this.repeatPassword).then((_) => {
        console.debug("New password set");
        window.location.href = "/";
      }).catch((err) => {
        this.isLoading = false;
        this.errorMessage = err;
      });
    }
  }

  onEnterKey() {
    if (this.isPasswordReset) {
      // If it's a password reset form, call the setNewPassword function
      this.setNewPassword();
    } else {
      // If it's a login form, call the onSignIn function
      this.onSignIn();
    }
  }
}