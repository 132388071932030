<div class="router-section">
  <div class="container-fluid">
    <div class="row mt-5 ml-5">
      <div class="col-md-11">
        <div class="title mb-8">
          <h1>Objects</h1>
        </div>
          <div class="btn-group btn-group-lg offset-md-10" role="group">
            <button type="button" class="btn search"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg></button>
            <button type="button" class="btn refresh"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-refresh" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                <path
                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg></button>
            <button type="button" class="btn grid"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
              </svg></button>
          </div>

          <div class="row">
            <ul class="nav nav-pills mb-3 offset-md-2" id="myTab0" role="tablist">

              <li class="nav-item" role="presentation">
                <button class="nav-link domains" id="domains-tab0" data-bs-toggle="tab" data-bs-target="#domains0"
                  type="button" role="tab" [ngClass]="{'active': activeTab === 'domains'}"
                  (click)="activeTab = 'domains'">
                  Domains
                </button>

              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link files" id="files-tab0" data-bs-toggle="tab" data-bs-target="#files0"
                  type="button" role="tab">
                  Files
                </button>

              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link ips" id="ips-tab0" data-bs-toggle="tab" data-bs-target="#ips0" type="button"
                  role="tab">
                  IPs
                </button>

              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link emails" id="emails-tab0" data-bs-toggle="tab" data-bs-target="#emails0"
                  type="button" role="tab">
                  Emails
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent0">

              <div class="tab-pane fade show active" id="domains0" role="tabpanel" aria-labelledby="domains-tab0">
                <div class="d-flex justify-content-center row">
                  <div class="col-md-10">
                    <div class="rounded">
                      <div class="table-responsive table-borderless">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">Domains</th>
                              <th class="text-center">TLP</th>
                              <th class="text-center">Tags</th>
                              <th class="text-center">Created</th>
                              <th class="text-center">Last Seen</th>
                            </tr>
                          </thead>
                          <tbody class="table-body">
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">thisismydomain.org</td>
                              <td>GREEN</td>
                              <td>credential-harvester</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="files0" role="tabpanel" aria-labelledby="files-tab0">
                <div class="d-flex justify-content-center row">
                  <div class="col-md-10">
                    <div class="rounded">
                      <div class="table-responsive table-borderless">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">Files</th>
                              <th class="text-center">TLP</th>
                              <th class="text-center">Tags</th>
                              <th class="text-center">Created</th>
                              <th class="text-center">Last Seen</th>
                            </tr>
                          </thead>
                          <tbody class="table-body">
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="ips0" role="tabpanel" aria-labelledby="ips-tab0">
                <div class="d-flex justify-content-center row">
                  <div class="col-md-10">
                    <div class="rounded">
                      <div class="table-responsive table-borderless">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">IPs</th>
                              <th class="text-center">TLP</th>
                              <th class="text-center">Tags</th>
                              <th class="text-center">Created</th>
                              <th class="text-center">Last Seen</th>
                            </tr>
                          </thead>
                          <tbody class="table-body">
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">192.168.100.18</td>
                              <td>GREEN</td>
                              <td>admin-user</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="files0" role="tabpanel" aria-labelledby="files-tab0">
                <div class="d-flex justify-content-center row">
                  <div class="col-md-10">
                    <div class="rounded">
                      <div class="table-responsive table-borderless">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">Files</th>
                              <th class="text-center">TLP</th>
                              <th class="text-center">Tags</th>
                              <th class="text-center">Created</th>
                              <th class="text-center">Last Seen</th>
                            </tr>
                          </thead>
                          <tbody class="table-body">
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">passwords.doc</td>
                              <td>RED</td>
                              <td>malicious-file</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="emails0" role="tabpanel" aria-labelledby="emails-tab0">
                <div class="d-flex justify-content-center row">
                  <div class="col-md-10">
                    <div class="rounded">
                      <div class="table-responsive table-borderless">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="text-center">Emails</th>
                              <th class="text-center">TLP</th>
                              <th class="text-center">Tags</th>
                              <th class="text-center">Created</th>
                              <th class="text-center">Last Seen</th>
                            </tr>
                          </thead>
                          <tbody class="table-body">
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>

                            <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                              <td class="text-center">kimberly.murphy@gmail</td>
                              <td>RED</td>
                              <td>Subject line:Q1 Report</td>
                              <td>MON, 2 FEB 2023 18:38:30 ET</td>
                              <td>MON, 13 MAR 2023 18:38:30 ET</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li class="page-item disabled">
                    <span class="page-link">Previous</span>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item active" aria-current="page">
                    <span class="page-link">2</span>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>