<div class="router-section">
    <div class="container-fluid m-5">
        <h1 *ngIf="isLoading" class="text-center w-100 mt-5">
            Loading results for {{ filename }}
            <div class="spinner-border text-blue" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </h1>
        <div *ngIf="!isLoading && !isError">
            <h2 class="text-center">VMRay Results for {{ filename }}</h2>
            <div class="row m-5">
                <div class="col-md-4">
                    <div class="card">
                        <h2 class="text-center mt-3">Verdict</h2>
                        <hr>
                        <div class="card-body">
                            <div class="card-title text-capitalize">
                                <h2 class="text-center">
                                    <i class="bi bi-check-circle-fill text-success"
                                        *ngIf="analysis['data.analysis_verdict'] === 'clean'"></i>
                                    <i class="bi bi-exclamation-triangle text-warning"
                                        *ngIf="analysis['data.analysis_verdict'] === 'suspicious'"></i>
                                    <i class="bi bi-radioactive text-danger"
                                        *ngIf="analysis['data.analysis_verdict'] === 'malicious'"></i>
                                    {{ analysis["data.analysis_verdict"] }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <h2 class="text-center mt-3">Job Details</h2>
                        <hr>
                        <div class="card-body">


                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5" 
                                               id="analysisSampleId" [value]="analysis['data.analysis_sample_id']">
                                        <label class="detail-label mb-2" for="analysisSampleId">VMRay Analysis ID</label>
                                    </div>
                                    
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSeverity"
                                            [value]="formatUnderscoredValue(analysis['data.analysis_severity'])">
                                        <label class="detail-label mb-2" for="analysisSeverity">Severity</label>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisVerdictReason"
                                            [value]="analysis['data.analysis_verdict_reason_description'] || 'No description provided'">
                                        <label class="detail-label" for="analysisVerdictReason">Verdict
                                            Description</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisYaraRuleMatchCt"
                                            [value]="analysis['data.analysis_yara_match_count']">
                                        <label class="detail-label" for="analysisYaraRuleMatchCt">YARA Rule
                                            Match
                                            Count</label>
                                    </div>
                                </div>
                            </div>





                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleMd5" [value]="analysis['data.analysis_sample_md5']">
                                        <label class="detail-label" for="analysisSampleMd5">MD5</label>
                                    </div>

                                </div>
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleSha1" [value]="analysis['data.analysis_sample_sha1']">
                                        <label class="detail-label" for="analysisSampleSha1">SHA1</label>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-floating mb-3">
                                            <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                                id="analysisSampleSSDeep"
                                                [value]="analysis['data.analysis_sample_ssdeep']">
                                            <label class="detail-label" for="analysisSampleSSDeep">SSDEEP</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-floating mb-3">
                                            <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                                id="analysisSampleSha256"
                                                [value]="analysis['data.analysis_sample_sha256']">
                                            <label class="detail-label" for="analysisSampleSha256">SHA256</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="text-end mt-3 mb-0">
                        <a class="btn btn-primary download-results-button me-2" (click)="downloadRawResults()"
                            [href]="downloadResultsHref" [download]="filename + '-vmray.json'">
                            <i class="bi bi-download"></i>&nbsp;
                            Download Results (.json)
                        </a>
                        <a *ngIf="analysis['data.analysis_verdict'] !== 'clean'"
                            class="btn btn-primary download-results-button me-2" (click)="downloadStixResults()"
                            [href]="downloadResultsHref" [download]="filename + '-vmray.json'">
                            <i class="bi bi-download"></i>&nbsp;
                            Download STIX Results (.json)
                        </a>







                        <button class="btn btn-primary download-results-button" (click)="downloadPdf()">
                            <span *ngIf="!isLoadingPdf && !isLoadingPdfError">
                                <i class="bi bi-file-earmark-richtext"></i>&nbsp;
                                Download PDF
                            </span>
                            <span *ngIf="isLoadingPdf">
                                <div class="spinner-border text-white" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isError">
            <div class="alert alert-danger" role="alert">
                An error occured - please reach out to the Gemini Administrators
            </div>
        </div>
    </div>
</div>