<div class="router-section">
  <div class="container-fluid">
    <div class="row m-5 mb-2">
      <div class="title">
        <h1>Upload</h1>
      </div>
      <!--
      <div class="col-md-4 offset-md-4">
        <div class="card mt-0 p-4">
          <h5 class="card-text upload-text">Choose an Endpoint</h5>
          <div class="card-body">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false" [disabled]="isSubmitting"> 
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false" [disabled]="true">
                {{ getEndpointDisplay() }}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button class="dropdown-item" type="button" (click)="endpoint = 'bmachine'">
                    Legacy B Machine
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" type="button" (click)="endpoint = 'stepFunctions'">
                    S3 Step Functions
                  </button>
                </li>
              </ul>
            </div>
            <h6 class="mt-2 mb-0">** functionality coming soon</h6>
          </div>
        </div>
      </div>
    </div>
  -->
      <div class="row mt-3 ml-5">
        <div class="col-md-5 offset-md-1">
          <div class="card p-4">
            <!--
          <input #fileUpload id="file-upload" class="file-upload mt-2 mb-2" type="file"
          (change)="onFileSelected($event)" [disabled]="isSubmitting" style="display: none;">
          <label class="file-name-input" [for]="fileUpload"></label>

-->
            <h3 class="card-title mb-0">Upload Malware</h3>
            <div class="btn btn-outline upload">
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor"
                class="bi bi-cloud-upload" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                <path fill-rule="evenodd"
                  d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
              </svg>
            </div>
            <div class="card-body mt-0">
              <div>
                <h5 class="card-text upload-text">Choose or drag a file here</h5>
                <h6 class="card-text upload-text">* supports STIX 1.1 & STIX 2.1 files</h6>

                <!-- Input for selecting multiple files -->
                <input id="file-upload" class="file-upload mt-2 mb-2" type="file" (change)="onFilesSelected($event)"
                  multiple [disabled]="isSubmitting">

                <div *ngIf="fileList.length > 0" class="mt-3">
                  <h6 class="card-text upload-text">Files being submitted:</h6>
                  <ul>
                    <li *ngFor="let file of fileList" class="file-list-item">
                      {{ file.name }}
                      <button class="btn btn-sm btn-danger" (click)="removeFile(file)">X</button>
                    </li>
                  </ul>
                </div>


              </div>
            </div>




          </div>
        </div>
        <div class="col-md-5">
          <div class="card1 p-3 pt-2">
            <div class="card-body">
              <h3 class="card-title mb-1 mt-3">
                Add Parameters:
              </h3>
              <div class="row">
                <div class="col-md-6 mt-4">
                  <!-- First form-check in its own column -->
                  <div class="form-check form-switch customswitch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckVT"
                      [(ngModel)]="isVTChecked" [disabled]="isSubmitting">
                    <label class="form-check-label" for="flexSwitchCheckVT">VirusTotal Analysis</label>
                  </div>
                </div>
                <div class="col-md-6 mt-4">
                  <!-- Second form-check in its own column -->
                  <div class="form-check form-switch customswitch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckReport"
                      [(ngModel)]="isCreateReportChecked" [disabled]="isSubmitting">
                    <label class="form-check-label" for="flexSwitchCheckReport">Create A Report</label>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-6 mt-3">
                  <div class="input-group mb-3">
                    <select class="form-select text-center btn btn-primary" id="inputGroupSelect01"
                      aria-describedby="basic-addon3">
                      <option hidden selected>Choose a TLP</option>
                      <option value="1">Amber</option>
                      <option value="2">Red</option>
                      <option value="3">Green</option>
                      <option value="4">White</option>
                    </select>
                  </div>
                </div>
          
                <div class="col-md-6 mt-3">
                  <div class="input-group ">
                    <select class="form-select text-center btn btn-primary" id="inputGroupSelect01"
                      aria-describedby="basic-addon3">
                      <option hidden selected>Choose an ACS Marking</option>
                      <option value="1">None</option>
                      <option value="2">Publicly Releasable </option>
                      <option value="3">Federal and non-Federal Entities</option>
                      <option value="4">Federal Entities</option>
                    </select>
              
                </div>
              </div>



            </div>
          </div>
        </div>

      </div>

      <!--
      <div class="col-md-5">
        <div class="card p-3 pt-2">
          <div class="card-body">
            <h5 class="card-text upload-text">
              Overwrite existing summaries
              and descriptions?
            </h5>
            <div class="dropdown mt-4">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false" [disabled]="isSubmitting"> 
              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false" [disabled]="true">
                {{ getOverwriteDisplay() }}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button class="dropdown-item" type="button" (click)="isOverwrite = true">
                    Yes, overwrite
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" type="button" (click)="isOverwrite = false">
                    No, do not overwrite
                  </button>
                </li>
              </ul>
            </div>
            <p class="card-text overwrite-summary pt-4">
              * New objects and relationships will be merged
              with existing data, if a report with the same
              incident number, report number, and
              report version exists.
            </p>
            <h6 class="mt-4 mb-0">** functionality coming soon</h6>
          </div>

        </div>
      </div>
    </div>
  -->
      <div class="row mt-5">
        <div class="col text-center">
          <div *ngIf="isSubmissionSuccessful">
            <h5>Submission successful! Navigate to Malware Analysis -> Results to see your current job status.</h5>
          </div>
          <button class="btn btn-primary submit-button" (click)="uploadMalware()"
            [disabled]="fileList.length === 0 || isSubmitting">
            <span id="submit-button-text" *ngIf="!isSubmitting && !isSubmissionSuccessful">
              <i class="bi bi-cloud-arrow-up"></i>&nbsp;
              Submit
            </span>
            <span id="submit-button-submitting-text" *ngIf="isSubmitting && !isSubmissionSuccessful">
              <div class="spinner-border text-light p-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span *ngIf="isSubmissionSuccessful">
              <i class="bi bi-cloud-check text-success"></i>
            </span>
          </button>
        </div>
      </div>

    </div>
  </div>