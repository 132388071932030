import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { PageStateService } from 'app/service/pagestate/page-state.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  providers: [DatePipe],
})


export class ResultsComponent implements OnInit, OnDestroy {
  currPage = 0;
  isMalwareJobsLoadingError: boolean = false;
  isMalwareJobsLoading: boolean = true;
  malwareJobs: any[] = []; // Stores Malware Jobs from API
  pageSize = 10;
  private refreshCount: number = 0;
  private maxRefreshCount: number = 3;
  private dataRefreshSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private pageStateService: PageStateService,
    private router: Router,
    private route: ActivatedRoute
  ) { this.dataRefreshSubscription = new Subscription(); }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private numberOfJobs: number = 0;

  ngOnInit(): void {
    this.currPage = this.pageStateService.getCurrentPage();

    // Initial check for new entries
    this.checkForNewEntries();

    // Periodic data refresh check every 1 minute to check if there are new entries
    this.dataRefreshSubscription = interval(10 * 1000)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {
      this.refreshCount++;
      if (this.refreshCount <= this.maxRefreshCount) {
        this.checkForNewEntries();
      } else {
        // Stop automatic refresh after 3 times
        this.dataRefreshSubscription.unsubscribe();
      }
    });
  }


  // If a new entry is detected then the table wil refresh based on this function
  private checkForNewEntries() {
    this.http.get(`${environment.ApiUrl}/malware/jobs`).subscribe({
      next: (response: any) => {
        const newMalwareJobs: any[] = response["Jobs"].filter((job: any) => !job.lionphish_upload);

        if (newMalwareJobs.length > this.numberOfJobs) {
          // If there are new entries, update the number of jobs and refresh
          this.numberOfJobs = newMalwareJobs.length;
          this.getMalwareJobs();
        }
      },
      error: (error: any) => {
        console.error(error);
        this.isMalwareJobsLoadingError = true;
      },
      complete: () => {
        console.log('API call completed');
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from the data refresh when the component is destroyed
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  leaveMainResultsTable() {
    this.pageStateService.setCurrentPage(this.currPage); // Store the current page
  }

  openDetailsPage(type: string, filenameId: string, filename: string): void {
    const url = `results/detail/${type}/${filenameId}/${filename}`;
    window.open(url, '_blank'); // Open the details page in a new tab
  }



  changePage(direction: number) {
    const nextPage = this.currPage + direction;

    if (nextPage >= 0 && nextPage < this.malwareJobs.length) {
      this.currPage = nextPage;
      this.pageStateService.setCurrentPage(this.currPage); // Store the current page
    }
  }

  getDateDisplay(date: string): string {
    const utcDate = new Date(date);
    const estDate = new Date(utcDate.getTime() - 3 * 60 * 60 * 1000); // Convert to EST (UTC-5)

    if (estDate) {
      const formattedDate = this.datePipe.transform(estDate, 'yyyy-MM-dd HH:mm:ss', 'EST');
      if (formattedDate !== null) {
        return formattedDate;
      }
    }

    return 'N/A'; // Handle cases where estDate or formattedDate is null
  }

  getProgressBarStyle(jobStatus: string): any {
    let width = '0%';

    switch (jobStatus) {
      case 'TOOL_EXECUTION_STARTED':
        width = '20%';
        break;
      case 'FILE_SUBMITTED':
        width = '50%';
        break;
      case 'PROCESSING':
        width = '70%';
        break;
      case 'FINISHED':
        width = '100%';
        break;
    }
    return { width };
  }


  getMalwareJobs() {
    const url = environment.ApiUrl + '/malware/jobs';

    this.http.get(url).subscribe({
      next: (response: any) => {
        this.malwareJobs = response["Jobs"]; // Store the API response data
        console.log(response);

        this.malwareJobs = this.malwareJobs.filter(job => !job.lionphish_upload);

        let analysisPaginated = [];

        while (this.malwareJobs.length > 0)
          analysisPaginated.push(this.malwareJobs.splice(0, this.pageSize));

        this.malwareJobs = analysisPaginated;
        console.debug(this.malwareJobs);
      },
      error: (error: any) => {
        console.error(error); // Handle any errors that occur during the API call
        this.isMalwareJobsLoadingError = true;
      },
      complete: () => {
        console.log('API call completed'); // Handle the completion of the API call if needed
        this.isMalwareJobsLoading = false;
      }
    });
  }
}
