<div class="router-section">
  <div class="container-fluid">
    <div class="row mt-5 ml-5"> 
      <div class="col-md-12">
        <div class="title mb-8">
          <h1>Malware Jobs</h1>
        </div>
        <div class="rounded">
          <div class="table-responsive table-borderless mt-5">
            <table class="table table-hover">
              <thead>
                <tr>
                  <!--<th class="text-center">lionphish_upload</th>-->
                  <th class="text-center">Filename</th>
                  <th class="text-center">Filename ID</th>
                  <th class="text-center">File Size</th>
                  <th class="text-center">Created At</th>
                  <th class="text-center">Job Status</th>
                  <!--<th class="text-center">Progress</th>-->
                  <th class="text-center">VMRay</th>
                  <th class="text-center">VirusTotal</th>
                </tr>
              </thead>
              <tbody *ngIf="malwareJobs.length > 0 && !isMalwareJobsLoading">
                <ng-container *ngFor="let job of malwareJobs[currPage]">
                  <tr *ngIf="job.filename !== 'malware-upload/lionphish/' && !job.lionphish_upload">
                    <!--<td class="malware-job-cell">{{ job.lionphish_upload }}</td>-->
                    <td class="malware-job-cell">{{ job.filename }}</td>
                    <td class="malware-job-cell">{{ job.filename_id }}</td>
                    <td class="malware-job-cell">{{ job.file_size | filesize }}</td>
                    <td class="malware-job-cell">{{ getDateDisplay(job.created_at) }}</td>
                    <!--<td class="malware-job-cell">{{ job.job_status }}</td>-->
                    <td class="malware-job-cell">{{ job.job_status }}
                      <div class="progress">
                        <div class="progress-bar"
                          [ngClass]="{
                            'job-started': job.job_status === 'TOOL_EXECUTION_STARTED',
                            'job-file-submitted': job.job_status === 'FILE_SUBMITTED',
                            'job-processing': job.job_status === 'PROCESSING',
                            'job-finished': job.job_status === 'FINISHED'
                          }"
                          [ngStyle]="getProgressBarStyle(job.job_status)">
                        </div>
                      </div>
                    </td>

                    <!--opens details in same page-->
                    <!--
                    <td class="text-center align-middle">
                      <button class="btn btn-md btn-primary" [disabled]="job.job_status != 'FINISHED'"
                        [routerLink]="['detail', 'vmray', job.filename_id, job.filename]">
                        <i class="bi bi-search"></i>
                      </button>
                    </td>

                  <td class="text-center align-middle">
                    <button class="btn btn-md btn-primary" [disabled]="job.job_status != 'FINISHED' || !job.vt_toggle"
                      [routerLink]="['detail', 'vt', job.filename_id, job.filename]">
                      <i class="bi bi-search"></i>
                    </button>
                  </td>
                  -->



                       <!--allows for opening in another tab-->
                   
                    <td class="text-center align-middle">
                      <button class="btn btn-md btn-primary"
                              [disabled]="job.job_status != 'FINISHED'"
                              (click)="openDetailsPage('vmray', job.filename_id, job.filename)">
                        <i class="bi bi-search"></i>
                      </button>
                    </td>

                    <td class="text-center align-middle">
                      <button class="btn btn-md btn-primary"
                              [disabled]="job.job_status != 'FINISHED' || !job.vt_toggle"
                              (click)="openDetailsPage('vt', job.filename_id, job.filename)">
                        <i class="bi bi-search"></i>
                      </button>
                    </td>

                 

                </tr>
              </ng-container>
              </tbody>
              <tbody *ngIf="!isMalwareJobsLoading && !isMalwareJobsLoadingError && malwareJobs.length == 0">
                <tr>
                  <td colspan="7" class="text-center">
                    No Jobs Found - Click Upload to Begin a New Job
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="isMalwareJobsLoading">
                <tr>
                  <td colspan="7" class="text-center">
                    <h3>
                      Loading&nbsp;
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </h3>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="isMalwareJobsLoadingError">
                <tr>
                  <td colspan="7">Error</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="pagination justify-content-end">
              <li class="page-item" (click)="changePage(1 - currPage)">
                <button type="button" class="page-link" [disabled]="currPage == 0">
                  First
                </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" (click)="changePage(-1)" [disabled]="currPage == 0">
                  Previous
                </button>
              </li>
              <li class="page-item"><span class="page-link">Page: {{ currPage + 1 }}</span></li>
              <li class="page-item">
                <button type="button" class="page-link" (click)="changePage(1)"
                  [disabled]="currPage + 1 == malwareJobs.length">
                  Next
                </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" (click)="changePage(malwareJobs.length - currPage)"
                  [disabled]="currPage + 1== malwareJobs.length">
                  End
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

  </div>
</div>