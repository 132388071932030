import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'
import { ReportsComponent } from './reporting/reports/reports.component';
import { ObjectsComponent } from './reporting/objects/objects.component';
import { AnalysisComponent } from './reporting/analysis/analysis.component';
import { ImportComponent } from './import/import.component';
import { ResultsComponent } from './malware-analysis/results/results.component';
import { SearchComponent } from './malware-analysis/search/search.component';
import { ProfileComponent } from './user/profile/profile.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoggedoutComponent } from './user/loggedout/loggedout.component';
import { VulnComponent } from './vuln/vuln.component';
import { ResultsDetailVMRayComponent } from './malware-analysis/results-detail-vmray/results-detail-vmray.component';
import { ResultsDetailVTComponent } from './malware-analysis/results-detail-vt/results-detail-vt.component';
import { AuthenticationGuard } from './service/authentication/authentication.guard';
import { LoginComponent } from './user/login/login.component';
import { CreateComponent } from './reporting/create/create.component';
import { EditComponent } from './edit/edit.component';

const routes: Routes = [
  { path: '', component: HomepageComponent, canActivate: [AuthenticationGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'results', component: ResultsComponent, canActivate: [AuthenticationGuard] },
  { path: 'results/detail/vmray/:filenameId/:filename', component: ResultsDetailVMRayComponent, canActivate: [AuthenticationGuard] },
  { path: 'results/detail/vt/:filenameId/:filename', component: ResultsDetailVTComponent, canActivate: [AuthenticationGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthenticationGuard] },
  { path: 'edit', component: EditComponent, canActivate: [AuthenticationGuard] },
  { path: 'create', component: CreateComponent, canActivate: [AuthenticationGuard] },
  { path: 'objects', component: ObjectsComponent, canActivate: [AuthenticationGuard] },
  { path: 'analysis', component: AnalysisComponent, canActivate: [AuthenticationGuard] },
  { path: 'import', component: ImportComponent, canActivate: [AuthenticationGuard] },
  { path: 'search', component: SearchComponent, canActivate: [AuthenticationGuard] },
  { path: 'search/:query', component: SearchComponent, canActivate: [AuthenticationGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthenticationGuard] },
  { path: 'vuln', component: VulnComponent, canActivate: [AuthenticationGuard] },
  { path: 'loggedout', component: LoggedoutComponent },
  // Default route everyone back to the homepage if not a valid path
  { path: '**', component: HomepageComponent, canActivate: [AuthenticationGuard] },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
