import { Component } from '@angular/core';
import { AuthenticationService } from 'app/service/authentication/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  isAuthenticated: boolean = false;

  // TODO add icons back in here
  menu = [
    {
      name: 'Reporting',
      isCollapsed: true,
      icon: 'bi-flag',
      submenus: [
        { title: 'Reports', link: 'reports' },
        { title: 'Create a Report', link: 'create' },
        { title: 'Objects', link: 'objects' },
        { title: 'Analysis', link: 'analysis' },
      ],
    },
    {
      title: 'Upload',
      link: 'import',
      isCollapsed: true,
      icon: 'bi-cloud-upload',
    },
    {
      name: 'Malware Analysis',
      isCollapsed: true,
      icon: 'bi-bug',
      submenus: [
        { title: 'Results', link: 'results' },
        { title: 'Search', link: 'search' },
      ],
    },
    {
      title: 'Vulnerability',
      link: 'vuln',
      isCollapsed: true,
      icon: 'bi-shield-exclamation',
    },
    {
      title: 'My Profile',
      link: 'profile',
      isCollapsed: true,
      icon: 'bi-person-circle',
    }
  ];

  constructor(
    private authService: AuthenticationService) {
    this.isAuthenticated = authService.isAuthenticated;
  }

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
    window.location.href = '/login';
  }
}
