import { Component } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  expanded: boolean = false;

  /*data = [
    {

      details: 'work plz',
    },
    // Add more data rows here
  ];

  expandedRowIndex: number = -1;

  */
  data = [
    { incident: '310101202056', report: '1', version: '1', organization: 'MPO', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '354545454536', report: '2', version: '1', organization: 'FBI', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '157776868443', report: '2', version: '1', organization: 'MPO', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '954524343567', report: '1', version: '1', organization: 'DHS', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '642567687898', report: '3', version: '1', organization: 'DHS', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '466878984355', report: '2', version: '1', organization: 'MPO', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '234354658789', report: '1', version: '1', organization: 'MPO', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    { incident: '754346535433', report: '2', version: '1', organization: 'FBI', created: 'Thu, 01 Jun 2023', modified: 'Mon, 19 July 2023', publication_status: 'Tue, 27 Feb 2024',  tlp: 'green', priority: 'medium', exercise:'no', summary: 'none', md5:'8vj89394oisjkx2', domain:'domain name', ip:'10.0.0.0'},
    /* Add more data rows here */
  ];

  expandedRow: any; // Store the data of the expanded row

  toggleRow(row: any) {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  isRowExpanded(row: any): boolean {
    return this.expandedRow === row;
  }


  openActionModel() {
    const modelDiv = document.getElementById('myActionModal');
    if (modelDiv != null) {
      modelDiv.style.display = 'block';
    }
  }

  CloseActionModel() {
    const modelDiv = document.getElementById('myActionModal');
    if (modelDiv != null) {
      modelDiv.style.display = 'none';
    }
  }

  openExportModel() {
    const modelDiv = document.getElementById('myModal');
    if (modelDiv != null) {
      modelDiv.style.display = 'block';
    }
  }

  CloseExportModel() {
    const modelDiv = document.getElementById('myModal');
    if (modelDiv != null) {
      modelDiv.style.display = 'none';
    }
  }
  /*
  toggleRow(index: number): void {
    if (this.expandedRowIndex === index) {
      this.expandedRowIndex = -1;
    } else {
      this.expandedRowIndex = index;
    }
  }
  
  getExpandIconClass(index: number): string {
    return this.expandedRowIndex === index ? 'bi bi-dash' : 'bi bi-plus';
  }
  isRowExpanded(index: number): boolean {
    return this.expandedRowIndex === index;
  }
  */
}



