<div class="container-fluid">
  <div class="row m-5 mb-2">
    <div class="title">
      <h1>MY PROFILE</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="110" viewBox="0 0 196.407 203.4">
          <g id="SVGRepo_iconCarrier" transform="translate(0 0.001)">
            <g id="Page-1" transform="translate(0 -0.001)">
              <g id="Dribbble-Light-Preview" transform="translate(0 0)">
                <g id="icons">
                  <path id="profile_round-_1342_" data-name="profile_round-[#1342]"
                    d="M246.653,2182.06H117.764c-6.937,0-12.059-7.118-9.443-13.627,12.14-30.1,40.659-47.389,73.88-47.389s61.752,17.287,73.89,47.389c2.622,6.509-2.5,13.627-9.437,13.627M142.1,2060.028c0-22.472,17.994-40.677,40.1-40.677s40.1,18.2,40.1,40.677-17.984,40.677-40.1,40.677-40.1-18.2-40.1-40.677M279.973,2178.4c-7.287-34.169-30.089-59.389-60.09-70.88a61.291,61.291,0,0,0,21.762-56.95c-3.948-26.643-25.821-48-52.226-51.15-36.449-4.271-67.364,24.508-67.364,60.608a61.135,61.135,0,0,0,22.475,47.492c-30.014,11.491-52.8,36.711-60.1,70.88-2.647,12.406,7.218,24,19.739,24H260.235c12.531,0,22.39-11.593,19.739-24"
                    transform="translate(-84 -1998.999)" fill="#e5faff" fill-rule="evenodd" />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="row">
          ________________________
        </div>
        <!-- Horizontal material form -->
        <form>
          <!-- Grid row -->
          <div class="row">
            <!-- Material input -->
            <label for="inputEmail3MD" class="col-md col-form-label">Email</label>
            <div class="col-sm-8">
              <div class="sm-form mt-0">
                <input type="email" class="form-control" id="inputEmail3MD" value="user@peraton.com" readonly>
              </div>
            </div>
          </div>
          <!-- Grid row -->
          <div class="row">
            <!-- Material input -->
            <label for="inputEmailVerified" class="col-md col-form-label">Email Verified</label>
            <div class="col-sm-8">
              <div class="sm-form mt-0">
                <input mdbInput type="emailverified" class="form-control" id="inputEmailVerified" value="True" readonly>
              </div>
            </div>
          </div>
          <!-- Grid row -->
          <div class="row">
            <!-- Material input -->
            <label for="inputSub" class="col-md col-form-label">Sub</label>
            <div class="col-sm-8">
              <div class="sm-form mt-0">
                <input mdbInput type="sub" class="form-control" id="inputSub" value="4qrfafaf-324q43a-qr3afqef"
                  readonly>
              </div>
            </div>
          </div>
          <!-- Grid row -->
          <div class="row">
            <!-- Material input -->
            <label for="inputCognito" class="col-md col-form-label">Cognito ID</label>
            <div class="col-sm-8">
              <div class="sm-form mt-0">
                <input mdbInput type="Cognito" class="form-control" id="inputCognito" value="27" readonly>
              </div>

            </div>

          </div>

        </form>
      </div>
    </div>
    <button type="button" class="btn btn-primary submit" (click)="openLogOutModel()">Log
      Out<!--<a class="nav-link" routerLink="/loggedout"> Log Out</a>--></button>
    <!-- The Action Modal -->
    <div class="modal LogOut" id="myLogOutModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

          <!-- Modal Header -->
          <div class="modal-body">
            <div class="card1">
              <h2>You are logged out of Gemini UAT</h2>
            </div>
            <button type="button" class="btn btn-primary login"><a
                href="https://auth.dfta.ninja/login?client_id=7fgp8leag94mhc09d665qe3sul&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fd13fkjcnrmse52.cloudfront.net%2F">
                <h3>Log Back In?</h3>
              </a></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>