import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PageStateService } from 'app/service/pagestate/page-state.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-vuln',
  templateUrl: './vuln.component.html',
  styleUrls: ['./vuln.component.scss']
})
export class VulnComponent implements OnInit {
  downloadResultsHref: any = '';
  start_date: string = ''; // or selectedDate: Date = new Date();
  end_date: string = '';
  isLoading: boolean = true;
  isLoadingPdf: boolean = false;
  isLoadingPdfError: boolean = false;



  isVulnDBLoadingError: boolean = false;
  isVulnDBLoading: boolean = true;
  vulnDB: any[] = []; // Stores Malware Jobs from API
  keyword: string = ''; // User input keyword
  totalItems: number = 0;
  totalPageCount: number = 1; // Initialize with a default value of 1
  filename: string = "";
  filenameId: string = "";
  vulndb_id: string = "";
  keywordEntered: boolean = false;
  currPage = 0;
  pageSize = 5;

  constructor(private http: HttpClient, private route: ActivatedRoute, private sanitizer: DomSanitizer, private pageStateService: PageStateService) { }

  ngOnInit(): void {
    this.filenameId = this.route.snapshot.paramMap.get('filenameId') || "";
    this.filename = this.route.snapshot.paramMap.get('filename') || "";

    console.debug(`Filename ID received: ${this.filenameId}`);
    console.debug(`Filename received: ${this.filename}`);
    this.postVulnDB();
  
    this.currPage = this.pageStateService.getCurrentPage(); 
  }
  downloadJsonFile(jsonData: any, filename: string) {
    console.log(jsonData);
    const dataStr = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  downloadObjectAsJson(obj: any) {
    const filename = 'vulndb_' + obj.vulndb_id + '.json';
    this.downloadJsonFile(obj, filename);
  }

  downloadPdf(vulndb_id: string) {
    this.isLoadingPdf = true;
    const url = `${environment.ApiUrl}/vulndb/report/${vulndb_id}`;

    this.http.get(url, { responseType: 'blob' }).subscribe({
      next: (response: any) => {
        console.debug("Presigned URL received - downloading PDF report...");
        console.debug(`Presigned URL: ${response["presigned_url"]}`);

        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);


        let link = document.createElement("a");
        link.style.display = 'none';
        link.href = url;
        link.download = vulndb_id + `_vulnDB.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.isLoadingPdf = false;
      },
      error: (error: any) => {
        console.error(error);
        this.isLoadingPdfError = true;
        this.isLoadingPdf = false; // Set to false in case of an error
      },
      complete: () => {
        console.debug("VulnDB PDF Retrieved Successfully!");
      }
    });
  }
  showJsonInBrowser(obj: any) {
    const jsonData = JSON.stringify(obj, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    // Open the JSON data in a new browser tab
    window.open(url, '_blank');

    // Revoke the URL to release resources
    window.URL.revokeObjectURL(url);
  }



  changePage(direction: number) {
    const nextPage = this.currPage + direction;

    if (nextPage >= 0 && nextPage < this.vulnDB.length) {
      this.currPage = nextPage;
      this.pageStateService.setCurrentPage(this.currPage); // Store the current page
    }
  }



  postVulnDB() {
    if (!this.keyword) {
      // If keyword is empty, reset the vulnDB and don't make the API call
      this.vulnDB = [];
      this.totalItems = 0; // Reset the totalItems count
      this.isVulnDBLoading = false;
      this.isVulnDBLoadingError = false; // Reset the error flag when starting a new search
      this.keywordEntered = false; // Reset the keywordEntered flag
      this.totalPageCount = 1; // Set totalPageCount to 1 when no results are expected
      return;
    }

    const baseUrl = `${environment.ApiUrl}/vulndb/vulnerabilities`;
    let url = `${baseUrl}?keyword=${encodeURIComponent(this.keyword)}`;

    // Append startDate if provided
    if (this.start_date) {
      url += `&start_date=${encodeURIComponent(this.start_date)}`;
    }

    // Append endDate if provided
    if (this.end_date) {
      url += `&end_date=${encodeURIComponent(this.end_date)}`;
    }

    this.vulnDB = []; // Clear the previous search results
    this.totalItems = 0; // Reset the totalItems count
    this.isVulnDBLoading = true; // Activate the loading spinner
    this.isVulnDBLoadingError = false; // Reset the error flag before making the API call
    this.keywordEntered = true;

    // Make the POST request
    this.http.post(url, null).subscribe({
      next: (response: any) => {
        this.vulnDB = response.results; // Store the API response data
        /*
        this.totalItems = response.total; // Get the total number of items from the API response
        this.currentPage = 1; // Reset the current page to 1 when new results are received

        // Set totalPageCount to 1 when there are no results
        this.totalPageCount = this.totalItems === 0 ? 1 : Math.ceil(this.totalItems / this.itemsPerPage);

       */

        let analysisPaginated = [];

        while (this.vulnDB.length > 0)
          analysisPaginated.push(this.vulnDB.splice(0, this.pageSize));

        this.vulnDB = analysisPaginated;
      },
      error: (error: any) => {
        console.error(error); // Handle any errors that occur during the API call
        this.isVulnDBLoadingError = true; // Set the error flag to true
        this.isVulnDBLoading = false; // Deactivate the loading spinner when an error occurs
      },
      complete: () => {
        console.log('API call completed'); // Handle the completion of the API call if needed
        this.isVulnDBLoading = false; // Deactivate the loading spinner
      }
    });
  }
}


