import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-results-detail-vt',
  templateUrl: './results-detail-vt.component.html',
  styleUrls: ['./results-detail-vt.component.scss']
})
export class ResultsDetailVTComponent {
  analysis: any;
  downloadResultsHref: any = '';
  isError: boolean = false;
  isLoading: boolean = true;
  filename: string = "";
  filenameId: string = "";

  constructor(private http: HttpClient, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.filenameId = this.route.snapshot.paramMap.get('filenameId') || "";
    this.filename = this.route.snapshot.paramMap.get('filename') || "";

    console.debug(`Filename ID received: ${this.filenameId}`);
    console.debug(`Filename received: ${this.filename}`);

    this.grabVTAnalysis();
  }

  downloadRawResults() {
    const rawResults = JSON.stringify(this.analysis);
    const uri = this.sanitizer.bypassSecurityTrustResourceUrl("data:text/json;charset=UTF-8," + encodeURIComponent(rawResults));
    this.downloadResultsHref = uri;
  }

  private grabVTAnalysis() {
    const url = `${environment.ApiUrl}/malware/analysis/vt/${this.filenameId}`;

    this.http.get(url).subscribe({
      next: (response: any) => {
        console.debug(response);
        this.analysis = response;
      },
      error: (error: any) => {
        console.error(error);
        this.isError = true;
      },
      complete: () => {
        console.debug("VT Analysis Data Retrieved Successfully!");
        this.isLoading = false;
      }
    });
  }
}