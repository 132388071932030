import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  activeCard: string = 'incident';

  ngOnInit(): void {
  this.setDefaultCard();
  }

  setDefaultCard() {
    this.activeCard = 'incident';
  }

  setActiveCard(cardName: string) {
    this.activeCard = cardName;
  }
  
}
