import { Component } from '@angular/core';

@Component({
  selector: 'app-loggedout',
  templateUrl: './loggedout.component.html',
  styleUrls: ['./loggedout.component.scss']
})
export class LoggedoutComponent {

}
