<div class="router-section">
  <div class="container-fluid">
    <div class="row mt-5 ml-5">
      <div class="col-md-11">
        <div class="title mb-8">
          <h1>Create a Report</h1>
        </div>
      </div>
      <div class="row m-5 mb-2">
        <div class="col-md-11">
          <div class="card1">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="input">File Name</label>
                    <input type="input" class="form-control" id="input">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="input">Hashes</label>
                    <input type="input" class="form-control" id="input">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Findings</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-left row">
            <div class="col-md-12">
              <div class="rounded">
                <div class="table-responsive table-borderless">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">Select Job:</th>
                        <th class="text-center">Filename</th>
                        <th class="text-center">Filename ID</th>
                        <th class="text-center">File Size</th>
                        <th class="text-center">Created At</th>
                        <th class="text-center">Job Status</th>
                        <th class="text-center">VMRay</th>
                        <th class="text-center">VirusTotal</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="malwareJobs.length > 0 && !isMalwareJobsLoading">
                      <tr *ngFor="let job of malwareJobs[currPage]">
                        <td class="malware-job-cell text-center">
                          <input type="checkbox" [(ngModel)]="job.selected" class="larger-checkbox">
                        </td>
                        <td class="malware-job-cell">{{ job.filename }}</td>
                        <td class="malware-job-cell">{{ job.filename_id }}</td>
                        <td class="malware-job-cell">{{ job.file_size | filesize }}</td>
                        <td class="malware-job-cell">{{ getDateDisplay(job.created_at) }}</td>
                        <td class="malware-job-cell">{{ job.job_status }}</td>
                        <td class="text-center align-middle">
                          <button class="btn btn-md btn-primary" [disabled]="job.job_status != 'FINISHED'"
                            [routerLink]="['detail', 'vmray', job.filename_id, job.filename]">
                            <i class="bi bi-search"></i>
                          </button>
                        </td>
                        <td class="text-center align-middle">
                          <button class="btn btn-md btn-primary"
                            [disabled]="job.job_status != 'FINISHED' || !job.vt_toggle"
                            [routerLink]="['detail', 'vt', job.filename_id, job.filename]">
                            <i class="bi bi-search"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!isMalwareJobsLoading && !isMalwareJobsLoadingError && malwareJobs.length == 0">
                      <tr>
                        <td colspan="7" class="text-center">
                          No Jobs Found - Click Upload to Begin a New Job
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="isMalwareJobsLoading">
                      <tr>
                        <td colspan="7" class="text-center">
                          <h3>
                            Loading&nbsp;
                            <div class="spinner-border text-white" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="isMalwareJobsLoadingError">
                      <tr>
                        <td colspan="7">Error</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="pagination justify-content-end">
                    <li class="page-item" (click)="changePage(1 - currPage)">
                      <button type="button" class="page-link" [disabled]="currPage == 0">
                        First
                      </button>
                    </li>
                    <li class="page-item">
                      <button type="button" class="page-link" (click)="changePage(-1)" [disabled]="currPage == 0">
                        Previous
                      </button>
                    </li>
                    <li class="page-item"><span class="page-link">Page: {{ currPage + 1 }}</span></li>
                    <li class="page-item">
                      <button type="button" class="page-link" (click)="changePage(1)"
                        [disabled]="currPage + 1 == malwareJobs.length">
                        Next
                      </button>
                    </li>
                    <li class="page-item">
                      <button type="button" class="page-link" (click)="changePage(malwareJobs.length - currPage)"
                        [disabled]="currPage + 1== malwareJobs.length">
                        End
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>