import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-results-detail-vmray',
  templateUrl: './results-detail-vmray.component.html',
  styleUrls: ['./results-detail-vmray.component.scss']
})
export class ResultsDetailVMRayComponent implements OnInit {
  objectContent: any;
  analysis: any;
  stix: any;
  downloadResultsHref: any = '';
  isError: boolean = false;
  isLoading: boolean = true;
  isLoadingPdf: boolean = false;
  isLoadingPdfError: boolean = false;
  filename: string = "";
  filenameId: string = "";

  constructor(private http: HttpClient, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }


  
  ngOnInit(): void {
    this.filenameId = this.route.snapshot.paramMap.get('filenameId') || "";
    this.filename = this.route.snapshot.paramMap.get('filename') || "";

    console.debug(`Filename ID received: ${this.filenameId}`);
    console.debug(`Filename received: ${this.filename}`);

    this.grabVMRayAnalysis();
    //this.grabSTIXJSON();
  }

  downloadPdf() {
    this.isLoadingPdf = true;

    const url = `${environment.ApiUrl}/malware/report/vmray/${this.filenameId}`;

    this.http.get(url).subscribe({
      next: (response: any) => {
        console.debug("Presigned URL received - downloading PDF report...");
        console.debug(`Presigned URL: ${response["presigned_url"]}`);

        let link = document.createElement("a");
        link.download = `-vmray.pdf`;
        link.href = response["presigned_url"];
        link.click();

        this.isLoadingPdf = false;
      },
      error: (error: any) => {
        console.error(error);
        this.isLoadingPdfError = true;
        this.isLoadingPdf = true;
      },
      complete: () => {
        console.debug("VMRay Analysis Data PDF Retrieved Successfully!");
      }
    });
  }

  downloadRawResults() {
    const rawResults = JSON.stringify(this.analysis);
    const uri = this.sanitizer.bypassSecurityTrustResourceUrl("data:text/json;charset=UTF-8," + encodeURIComponent(rawResults));
    this.downloadResultsHref = uri;
  }

  downloadStixResults() {
    const stixResults = JSON.stringify(this.stix);
    const uri = this.sanitizer.bypassSecurityTrustResourceUrl("data:text/json;charset=UTF-8," + encodeURIComponent(stixResults));
    this.downloadResultsHref = uri;
  }

  formatUnderscoredValue(value: string): string {
    let i, frags = value.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  private async grabVMRayAnalysis() {
    const url = `${environment.ApiUrl}/malware/analysis/vmray/${this.filenameId}`;

    this.http.get(url).subscribe({
      next: (response: any) => {
        console.debug(response);
        this.analysis = response;

        if (this.analysis && (this.analysis['data.analysis_verdict'] === 'suspicious' || this.analysis['data.analysis_verdict'] === 'malicious')) {
          this.grabSTIXJSON();
        } else {
          this.isLoading = false;
        }
      },
      error: (error: any) => {
        console.error(error);
        this.isError = true;
        this.isLoading = false;
      },
      complete: () => {
        console.debug("VMRAY Analysis Data Retrieved Successfully!");
      }
    });
  }


  private async grabSTIXJSON() {
    const url = `${environment.ApiUrl}/malware/stix-2-1/vmray/${this.filenameId}`;
  
    try {
      const response = await this.http.get(url).toPromise();
      console.debug(response);
      this.stix = response;
      console.log(response);
      console.debug("VMRay STIX JSON Retrieved Successfully!");
    } catch (error) {
      console.error(error);
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

}
  


