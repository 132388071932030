<!-- Footer -->
<footer>
    <div class="text-center p-2 banner">
        <span class="text-light text-underline">
            {{bannerText}}
            <button type="button" class="btn btn-md btn-light p-0 px-2" (click)="openModal(disclaimer)">
                Click for View Disclaimer
            </button>
        </span>
    </div>
</footer>

<ng-template #disclaimer>
    <div class="modal-header">
        <h4 class="modal-title pull-left">DHS Disclaimer</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{disclaimerText}}
    </div>
</ng-template>