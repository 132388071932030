<div class="sidenav">
  <img src="/assets/Gemini-Logo.png" class="img-fluid">
  <div *ngFor="let item of menu" class="container">
    <div *ngIf="item.submenus;then hasSubmenu else singleLink"></div>

    <ng-template #singleLink>
      <div class="row no-gutters justify-content-center">
        <a [routerLink]="item.link" routerLinkActive="active-link" class="col">
          <i class="bi" [class]="item.icon"></i>
          {{item.title}}
        </a>
      </div>
    </ng-template>

    <ng-template #hasSubmenu>
      <p (click)="item.isCollapsed = !item.isCollapsed">
        <i class="bi" [class]="item.icon"></i>
        {{item.name}}
        <i class="bi" [class.bi-chevron-down]="item.isCollapsed" [class.bi-chevron-up]="!item.isCollapsed"></i>
      </p>

      <div [collapse]="item.isCollapsed" [isAnimated]="true">
        <a *ngFor="let subitem of item.submenus" [routerLink]="subitem.link" routerLinkActive="active-link"
          class="row no-gutters justify-content-center">{{subitem.title}}</a>
      </div>
    </ng-template>
  </div>
  <div class="row no-gutters justify-content-center">
    <a [routerLink]="'login'" routerLinkActive="active-link" class="col" (click)="logout()">
      <i class="bi bi-box-arrow-left"></i>
      Logout
    </a>
  </div>
</div>