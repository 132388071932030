<div class="router-section">
  <div class="container-fluid">
    <div class="row mt-5 ml-5">
      <div class="col-md-11">
        <div class="title mb-8">
            <h1>Analysis</h1>
          </div>
          <div class="btn-group btn-group-lg offset-md-9 mb-4" role="group">
              <button type="button" class="btn search"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg></button>
              <button type="button" class="btn refresh"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-refresh" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                  <path
                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg></button>
              <button type="button" class="btn grid"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
                  <path
                    d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                </svg></button>
            </div>
            <div class="d-flex justify-content-center row">
              <div class="col-md-10">
                <div class="rounded">
                  <div class="table-responsive table-borderless">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th class="text-center">Timestamp</th>
                          <th class="text-center">Purpose</th>
                          <th class="text-center">Incident No.</th>
                          <th class="text-center">Source IP</th>
                          <th class="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody class="table-body">
                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>

                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>
                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>
                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>
                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>
                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>
                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>

                        <tr class="cell-1" data-toggle="collapse" data-target="#reports" color="red">
                          <td>MON, 2 FEB 2023 18:38:30 ET</td>
                          <td>Added http://google to 109340348.r1.v1</td>
                          <td>109340348</td>
                          <td>172.187.06</td>
                          <td>Complete</td>
                        </tr>
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-end">
                        <li class="page-item disabled">
                          <span class="page-link">Previous</span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item active" aria-current="page">
                          <span class="page-link">2</span>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                          <a class="page-link" href="#">Next</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
 
      </div>