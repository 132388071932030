import { Component } from '@angular/core';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  
openLogOutModel(){
  const modelDiv = document.getElementById('myLogOutModal');
  if(modelDiv!= null) {
  modelDiv.style.display = 'block';
  }
}


}
