import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, Subscriber, forkJoin, of } from 'rxjs';
import { catchError, concatAll, map, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class S3ServiceService {
  uploadFiles(files: File[], hasVirusTotal: boolean | undefined) {
    return forkJoin(files.flatMap(file => this.uploadFile(file, hasVirusTotal)));
  }

  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  /**
   *
   * @param fileName Name of the file that is being uploaded
   * @returns Presigned Url to upload that file
   */
  getUploadPresignedUrl(fileName: string, hasVirusTotal: boolean) {
    const url = `${environment.ApiUrl}/presignedupload/${fileName}?virustotal=${hasVirusTotal}`;
    return this.http.get(url, { responseType: 'text' });
  }

  uploadFile(file: File, hasVirusTotal = false): Observable<any> {
    const fileName = file.name.replaceAll(' ', '-');

    return this.getUploadPresignedUrl(fileName, hasVirusTotal).pipe(
      mergeMap(url => {
        return this.http.put(url, file, this.httpOptions);
      }),
      catchError((err) => {
        return err;
      })
    );
  }


}