import { NgModule } from '@angular/core';
import { AppRoutingModule } from "./app-routing.module";
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { ReportsComponent } from './reporting/reports/reports.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObjectsComponent } from './reporting/objects/objects.component';
import { AnalysisComponent } from './reporting/analysis/analysis.component';
import { ResultsComponent } from './malware-analysis/results/results.component';
import { SearchComponent } from './malware-analysis/search/search.component';
import { ProfileComponent } from './user/profile/profile.component';
import { ImportComponent } from './import/import.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoggedoutComponent } from './user/loggedout/loggedout.component';
import { HttpClientModule } from '@angular/common/http';
import { VulnComponent } from './vuln/vuln.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ResultsDetailVMRayComponent } from './malware-analysis/results-detail-vmray/results-detail-vmray.component';
import { ResultsDetailVTComponent } from './malware-analysis/results-detail-vt/results-detail-vt.component';
import { NgxFilesizeModule } from "ngx-filesize";
import { CommonModule } from '@angular/common';
import { LoginComponent } from './user/login/login.component';
import { CreateComponent } from './reporting/create/create.component';
import { FooterComponent } from './footer/footer.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { EditComponent } from './edit/edit.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReportsComponent,
    ObjectsComponent,
    AnalysisComponent,
    ResultsComponent,
    SearchComponent,
    ProfileComponent,
    ImportComponent,
    NavbarComponent,
    HomepageComponent,
    LoggedoutComponent,
    ResultsDetailVMRayComponent,
    ResultsDetailVTComponent,
    CreateComponent,
    FooterComponent,
    VulnComponent,
    EditComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TimepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgxFilesizeModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
