<div class="router-section">
    <div class="container-fluid">
        <div class="row m-5 mb-2">
            <div class="title">
                Edit
            </div>
            <div class="col-md-3">
                <div class="card1">
                    <div class="row">
                        <nav class="col-md-12 sidebar">
                            <div class="sidebar-sticky">
                                <ul class="nav flex-column">
                                    <li class="nav-item">
                                        <!-- Use setActiveCard method to set the active card -->
                                        <a class="nav-link" (click)="setActiveCard('incident')"
                                            [ngClass]="{ 'active-nav-item': activeCard === 'incident' }">
                                            <h2 class="mb-0">Incident</h2>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" (click)="setActiveCard('report')"
                                            [ngClass]="{ 'active-nav-item': activeCard === 'report' }">
                                            <h2 class="mb-0">Report</h2>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" (click)="setActiveCard('evidence1')"
                                            [ngClass]="{ 'active-nav-item': activeCard === 'evidence1' }">
                                            <h2 class="mb-0">Evidence 1</h2>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" (click)="setActiveCard('evidence2')"
                                            [ngClass]="{ 'active-nav-item': activeCard === 'evidence2' }">
                                            <h2 class="mb-0">Evidence 2</h2>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" (click)="setActiveCard('mitigation')"
                                            [ngClass]="{ 'active-nav-item': activeCard === 'mitigation' }">
                                            <h2 class="mb-0">Mitigation</h2>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" (click)="setActiveCard('conclusion')"
                                            [ngClass]="{ 'active-nav-item': activeCard === 'conclusion' }">
                                            <h2 class="mb-0">Conclusion</h2>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>
                </div>
                <div class="card2">
                    <h4 class="card-title mt-0">Add Evidence</h4>

                    <div class="card-body">
                        <button class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80"
                                fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                <path
                                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg></button>
                    </div>
                </div>
            </div>
            <div class="col-md-8 offset-md-2 m-0">
                <div class="card3">
                    <div [ngSwitch]="activeCard">
                        <div *ngSwitchCase="'incident'">
                            <h1 class="card-title">Incident | 1048453118</h1>

                            <div class="btn-group btn-group-lg" role="group">
                                <button type="button" class="btn arrows "><svg xmlns="http://www.w3.org/2000/svg"
                                        width="30" height="30" fill="currentColor" class="bi bi-arrow-left-right"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                    </svg></button>
                                <button type="button" class="btn pencil"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="30" height="30" fill="currentColor" class="bi bi-pencil"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                    </svg></button>
                            </div>
                            <div class="card-body">
                                <div class="container-fluid">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-4 ">
                                                <label for="incident-num" class="incident-label">Incident Number</label>
                                                <div class="incident-num">
                                                    <input type="text" class="incident-num-input" placeholder="">

                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <label for="exercise" class="exercise-label">Exercise</label>
                                                <div class="exercise">
                                                    <input type="text" class="exercise-input" placeholder="">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 p-4">
                                                <label for="organization"
                                                    class="organization-label">Organization</label>
                                                <div class="organization">
                                                    <input type="text" class="organization-input" placeholder="">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 p-4">
                                                <div class="form-group">
                                                    <label for="summary" class="summary-label">Incident Summary</label>
                                                    <textarea class="form-control" id="summary" rows="5"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'report'">
                            <h1 class="card-title">Report | 1048453118</h1>
                            <div class="card-body">
                                <div class="container-fluid">
                                    <div class="container">
                                        <div class="btn-group btn-group-lg" role="group">
                                            <button type="button" class="btn add "><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                    fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></button>
                                            <button type="button" class="btn refresh "><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-arrow-clockwise"
                                                    viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd"
                                                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                    <path
                                                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                </svg></button>
                                            <button type="button" class="btn pencil"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 p-4 ">
                                                <label for="report" class="report-label">Report Number</label>
                                                <div class="report">
                                                    <input type="text" class="report-input" placeholder="">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 p-4">
                                                <label for="tlp" class="tlp-label">TLP</label>
                                                <div class="tlp">
                                                    <input type="text" class="tlp-input" placeholder="">

                                                </div>
                                            </div>
                                            <div class="col-6 p-4">
                                                <label for="priority" class="priority-label">Priority</label>
                                                <div class="priority">
                                                    <input type="text" class="priority-input" placeholder="">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 p-4">
                                                <label for="report-ver" class="report-ver-label">Report Version</label>
                                                <div class="report-ver">
                                                    <input type="text" class="report-ver-input" placeholder="">

                                                </div>
                                            </div>
                                            <div class="col-6 p-4">
                                                <label for="report-type" class="report-type-label">Report Type</label>
                                                <div class="report-type">
                                                    <input type="text" class="report-type-input" placeholder="">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 p-4">
                                                <div class="form-group">
                                                    <label for="report-summary" class="report-summary-label">Report
                                                        Summary</label>
                                                    <textarea class="form-control" id="report-summary"
                                                        rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'mitigation'">
                            <h1 class="card-title">Mitigation</h1>
                            <div class="card-body">
                                <div class="container-fluid">
                                    <div class="container">
                                        <div class="btn-group btn-group-lg" role="group">
                                            <button type="button" class="btn pencil"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 p-4 md-5">
                                                <div class="form-group">
                                                    <label for="mititgation" class="mitigation-label">Mitigation</label>
                                                    <textarea class="form-control" id="mitigation" rows="6"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'conclusion'">
                            <h1 class="card-title">Conclusion</h1>
                            <div class="card-body">
                                <div class="container-fluid">
                                    <div class="container">
                                        <div class="btn-group btn-group-lg" role="group">
                                            <button type="button" class="btn pencil"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 p-4 md-5">
                                                <div class="form-group">
                                                    <label for="conclusion" class="conclusion-label">Conclusion</label>
                                                    <textarea class="form-control" id="conclusion" rows="6"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'evidence1'">
                            <h1 class="card-title">Evidence 1 | File</h1>

                            <div class="card-body">
                                <div class="btn-group btn-group-lg" role="group">
                                    <button type="button" class="btn cancel "><svg xmlns="http://www.w3.org/2000/svg"
                                            width="40" height="40" fill="currentColor" class="bi bi-x"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg></button>
                                    <button type="button" class="btn count">35</button>
                                    <button type="button" class="btn ellipse"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="30" height="30" fill="currentColor" class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></button>
                                </div>
                                <div class="row">
                                    <div class="col-4 ">
                                        <label for="object-tlp" class="object-tlp-label">Object TLP</label>
                                        <div class="object-tlp">
                                            <input type="text" class="object-tlp-input" placeholder="">
                                            <button class="object-tlp-edit-button"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <label for="tags" class="tags-label">Tags</label>
                                        <div class="tags">
                                            <input type="text" class="tags-input" placeholder="">
                                            <button class="tags-edit-button"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="30" height="30" fill="currentColor" class="bi bi-pencil"
                                                    viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <label for="file-paths" class="file-paths-label">File Paths</label>
                                        <div class="file-paths">
                                            <input type="text" class="file-paths-input" placeholder="">
                                            <button class="file-paths-plus-button"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></button>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label for="file-names" class="file-names-label">File Names</label>
                                        <div class="file-names">
                                            <input type="text" class="file-names-input" placeholder="">
                                            <div class="btn-group-names" role="group">
                                                <button class="plus-names-button"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                                                        fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                    </svg></button>
                                                <button class="delete-button"><svg xmlns="http://www.w3.org/2000/svg"
                                                        width="40" height="40" fill="currentColor" class="bi bi-x"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg></button>
                                                <button class="edit-names-button"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                        fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                        <path
                                                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                    </svg></button>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <h6> Select a category:</h6>
                                        <ul class="nav nav-pills mb-3" id="myTab0" role="tablist">

                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link metadata active" id="metadata-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#metadata0" type="button"
                                                    role="tab">
                                                    Metadata
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link antivirus" id="antivirus-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#antivirus0" type="button"
                                                    role="tab" routerLinkActive="active">
                                                    Antivirus
                                                </button>

                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link ssdeep" id="ssdeep-tab0" data-bs-toggle="tab"
                                                    data-bs-target="#ssdeep0" type="button" role="tab">
                                                    ssdeep
                                                </button>

                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link screenshots" id="screenshots-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#screenshots0" type="button"
                                                    role="tab">
                                                    Screenshots
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link relationships" id="relationships-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#relationships0" type="button"
                                                    role="tab">
                                                    Relationships
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link description" id="description-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#description0" type="button"
                                                    role="tab">
                                                    Description
                                                </button>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent0">

                                            <div class="tab-pane fade show active" id="metadata0" role="tabpanel"
                                                aria-labelledby="metadata-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4 mb-2 m-0 mt-2">

                                                        <div class="card-body">

                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <table class="table table-bordered table-left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>File Type</th>
                                                                                <td>MS-DOS Executable PE32 (GUI) Intel
                                                                                    129230, for MS Windows</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th>File Size</th>
                                                                                <td>34 KB</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>MD5</th>
                                                                                <td>MD5 Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>SHA 1</th>
                                                                                <td>SHA 1 Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>SHA 512</th>
                                                                                <td>SHA 512 Result</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <table class="table table-bordered table-left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>SHA 256</th>
                                                                                <td>SHA 256 Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>ssdeep</th>
                                                                                <td>ssdeep Result</td>

                                                                            <tr>
                                                                                <th>Entrophy</th>
                                                                                <td>Entrophy Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Malware Result</th>
                                                                                <td>Malicious</td>
                                                                            </tr>
                                                                            <!-- Add more rows here as needed -->
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            <button class="edit-metadata-button"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="30"
                                                                    height="30" fill="currentColor" class="bi bi-pencil"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                </svg></button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="antivirus0" role="tabpanel"
                                                aria-labelledby="antivirus-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Antivirus Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="ssdeep0" role="tabpanel"
                                                aria-labelledby="ssdeep-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> SSDEEP Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="screenshots0" role="tabpanel"
                                                aria-labelledby="screenshots-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Screenshots Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="relationships0" role="tabpanel"
                                                aria-labelledby="relationships-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Relationship Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="description0" role="tabpanel"
                                                aria-labelledby="description-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Description Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'evidence2'">
                            <h1 class="card-title">Evidence 2 | File</h1>

                            <div class="card-body">
                                <div class="btn-group btn-group-lg" role="group">
                                    <button type="button" class="btn cancel "><svg xmlns="http://www.w3.org/2000/svg"
                                            width="40" height="40" fill="currentColor" class="bi bi-x"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg></button>
                                    <button type="button" class="btn count">35</button>
                                    <button type="button" class="btn ellipse"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="30" height="30" fill="currentColor" class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></button>
                                </div>
                                <div class="row">
                                    <div class="col-4 ">
                                        <label for="object-tlp" class="object-tlp-label">Object TLP</label>
                                        <div class="object-tlp">
                                            <input type="text" class="object-tlp-input" placeholder="">
                                            <button class="object-tlp-edit-button"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <label for="tags" class="tags-label">Tags</label>
                                        <div class="tags">
                                            <input type="text" class="tags-input" placeholder="">
                                            <button class="tags-edit-button"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="30" height="30" fill="currentColor" class="bi bi-pencil"
                                                    viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <label for="file-paths" class="file-paths-label">File Paths</label>
                                        <div class="file-paths">
                                            <input type="text" class="file-paths-input" placeholder="">
                                            <button class="file-paths-plus-button"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg></button>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label for="file-names" class="file-names-label">File Names</label>
                                        <div class="file-names">
                                            <input type="text" class="file-names-input" placeholder="">
                                            <div class="btn-group-names" role="group">
                                                <button class="plus-names-button"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                                                        fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                    </svg></button>
                                                <button class="delete-button"><svg xmlns="http://www.w3.org/2000/svg"
                                                        width="40" height="40" fill="currentColor" class="bi bi-x"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg></button>
                                                <button class="edit-names-button"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                        fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                                        <path
                                                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                    </svg></button>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <h6> Select a category:</h6>
                                        <ul class="nav nav-pills mb-3" id="myTab0" role="tablist">

                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link metadata active" id="metadata-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#metadata0" type="button"
                                                    role="tab">
                                                    Metadata
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link antivirus" id="antivirus-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#antivirus0" type="button"
                                                    role="tab" routerLinkActive="active">
                                                    Antivirus
                                                </button>

                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link ssdeep" id="ssdeep-tab0" data-bs-toggle="tab"
                                                    data-bs-target="#ssdeep0" type="button" role="tab">
                                                    ssdeep
                                                </button>

                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link screenshots" id="screenshots-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#screenshots0" type="button"
                                                    role="tab">
                                                    Screenshots
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link relationships" id="relationships-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#relationships0" type="button"
                                                    role="tab">
                                                    Relationships
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link description" id="description-tab0"
                                                    data-bs-toggle="tab" data-bs-target="#description0" type="button"
                                                    role="tab">
                                                    Description
                                                </button>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent0">

                                            <div class="tab-pane fade show active" id="metadata0" role="tabpanel"
                                                aria-labelledby="metadata-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4 mb-2 m-0 mt-2">

                                                        <div class="card-body">

                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <table class="table table-bordered table-left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>File Type</th>
                                                                                <td>MS-DOS Executable PE32 (GUI) Intel
                                                                                    129230, for MS Windows</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <th>File Size</th>
                                                                                <td>34 KB</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>MD5</th>
                                                                                <td>MD5 Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>SHA 1</th>
                                                                                <td>SHA 1 Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>SHA 512</th>
                                                                                <td>SHA 512 Result</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <table class="table table-bordered table-left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>SHA 256</th>
                                                                                <td>SHA 256 Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>ssdeep</th>
                                                                                <td>ssdeep Result</td>

                                                                            <tr>
                                                                                <th>Entrophy</th>
                                                                                <td>Entrophy Result</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Malware Result</th>
                                                                                <td>Malicious</td>
                                                                            </tr>
                                                                            <!-- Add more rows here as needed -->
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            <button class="edit-metadata-button"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="30"
                                                                    height="30" fill="currentColor" class="bi bi-pencil"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                </svg></button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="antivirus0" role="tabpanel"
                                                aria-labelledby="antivirus-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Antivirus Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="ssdeep0" role="tabpanel"
                                                aria-labelledby="ssdeep-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> SSDEEP Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="screenshots0" role="tabpanel"
                                                aria-labelledby="screenshots-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Screenshots Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="relationships0" role="tabpanel"
                                                aria-labelledby="relationships-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Relationship Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="description0" role="tabpanel"
                                                aria-labelledby="description-tab0">
                                                <div class="d-flex justify-content-center row">
                                                    <div class="card4">

                                                        <div class="card-body">
                                                            <div class="input-group mb-3">
                                                                <h5> Description Information will appear here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>