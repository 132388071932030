<div class="router-section">
    <div class="container-fluid">
        <div class="row mt-5 ml-5">
            <div class="col-md-12">
                <div class="title m-5 mb-0 mt-0">
                    <h1>Reports</h1>
                </div>

                <div class="btn-group btn-group-lg offset-10" role="group">
                    <button type="button" class="btn search"> <svg xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg></button>
                    <button type="button" class="btn refresh"><svg xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" class="bi bi-refresh" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path
                                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg></button>
                    <button type="button" class="btn grid"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi bi-grid" viewBox="0 0 16 16">
                            <path
                                d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                        </svg></button>
                </div>



                <div class="row align-items-center justify-content-center no-gutters">
                    <div class="rounded col-12">
                        <div class="table-responsive table-borderless mt-5">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="text-header"></th>
                                        <th class="text-header">Incident #</th>
                                        <th class="text-header">Report #</th>
                                        <!--<th class="text-header">Version</th>-->
                                        <th class="text-header">Organization</th>
                                        <th class="text-header">Created</th>
                                        <th class="text-header">Modified</th>
                                        <th class="text-header">Publication Status</th>
                                        <th class="text-header"></th>
                                        <th class="text-header"></th>
                                        <!-- Add other table headers here -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Loop through the data and create table rows -->
                                    <ng-container *ngFor="let row of data">
                                        <tr (click)="toggleRow(row)">
                                            <!-- Display plus or minus icon based on the expanded state -->
                                            <td class="text-center">
                                                <span>
                                                    <i *ngIf="!isRowExpanded(row)" class="bi bi-plus"></i>
                                                    <i *ngIf="isRowExpanded(row)" class="bi bi-dash"></i>
                                                </span>
                                            </td>
                                            <td class="text-center">{{ row.incident }}</td>
                                            <td class="text-center">{{ row.report }}</td>
                                            <!--<td class="text-center">{{ row.version }}</td>-->
                                            <td class="text-center">{{ row.organization }}</td>
                                            <td class="text-center">{{ row.created }}</td>
                                            <td class="text-center">{{ row.modified }}</td>
                                            <td class="text-center">{{ row.publication_status }}</td>
                                            <td class="text-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-fail" viewBox="0 0 16 16">
                                                    <circle cx="8" cy="8" r="8" />
                                                </svg>
                                            </td>
                                            <td class="text-center">
                                                <div class="btn-toolbar">
                                                    <button type="button" id="btnActions"
                                                        class="btn btn-primary btn-sm actions"
                                                        (click)="openActionModel()">Actions</button>
                                                    <button type="button" id="btnExport"
                                                        class="btn btn-primary btn-sm export"
                                                        (click)="openExportModel()">Export</button>
                                                    <button type="button" id="btnActions"
                                                        class="btn btn-primary btn-sm actions"
                                                        (click)="openActionModel()">TAXII</button>
                                                </div>
                                            </td>
                                            <!-- Add other table cells here -->
                                        </tr>
                                        <!-- Expanded row with ngIf -->
                                        <tr *ngIf="expandedRow === row"
                                            [ngClass]="{'expanded-row': isRowExpanded(row)}">
                                            <td colspan="9">
                                                <!-- Set the colspan to the number of columns in your table -->
                                                <!-- Nested table to represent the expanded cell content -->
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="expanded">TLP</th>
                                                            <th class="expanded">Priority</th>
                                                            <th class="expanded">Exercise</th>
                                                            <th class="expanded">Summary</th>
                                                            <th class="expanded">MD5</th>
                                                            <th class="expanded">Domain</th>
                                                            <th class="expanded">IP</th>
                                                            <!-- Add more column headers as needed -->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="expanded-result">{{ row.tlp }}</td>
                                                            <td class="expanded-result">{{ row.priority }}</td>
                                                            <td class="expanded-result">{{ row.exercise }}</td>
                                                            <td class="expanded-result">{{ row.summary }}</td>
                                                            <td class="expanded-result">{{ row.md5 }}</td>
                                                            <td class="expanded-result">{{ row.domain }}</td>
                                                            <td class="expanded-result">{{ row.ip }}</td>
                                                            <!-- Add more data rows as needed -->
                                                        </tr>
                                                        <!-- Add more data rows as needed -->
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>

                            <!-- The Action Modal -->
                            <div class="modal action offset-md-1" id="myActionModal">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">

                                        <!-- Modal Header -->
                                        <div class="modal-header">
                                            <h4 class="modal-title">ACTIONS</h4>
                                            <button type="button" class="btn-close" (click)="CloseActionModel()"
                                                data-bs-dismiss="modal"></button>
                                        </div>

                                        <!-- Modal body -->
                                        <div class="modal-body">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary edit mb-5"
                                                            routerLink="/edit"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="50" height="50" fill="currentColor"
                                                                class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fill-rule="evenodd"
                                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg>
                                                            <h5>EDIT</h5>
                                                        </button>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary duplicate"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="50"
                                                                height="50" fill="currentColor"
                                                                class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
                                                                <path
                                                                    d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                                            </svg>
                                                            <h5>DUPLICATE</h5>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary delete"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="60"
                                                                height="60" fill="currentColor" class="bi bi-x"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                            <h5>DELETE</h5>
                                                        </button>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary zip"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="50"
                                                                height="50" fill="currentColor"
                                                                class="bi bi-file-zip-fill" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M8.5 9.438V8.5h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243z" />
                                                                <path
                                                                    d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm2.5 8.5v.938l-.4 1.599a1 1 0 0 0 .416 1.074l.93.62a1 1 0 0 0 1.109 0l.93-.62a1 1 0 0 0 .415-1.074l-.4-1.599V8.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1zm1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1v1z" />
                                                            </svg>
                                                            <h5>ZIP</h5>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- The Export Modal -->
                            <div class="modal action offset-md-1" id="myModal">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">


                                        <!-- Modal Header -->
                                        <div class="modal-header">
                                            <h4 class="modal-title">EXPORT</h4>
                                            <button type="button" class="btn-close" (click)="CloseExportModel()"
                                                data-bs-dismiss="modal"></button>
                                        </div>

                                        <!-- Modal body -->
                                        <div class="modal-body">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary txt">
                                                            <h3>TXT</h3>
                                                        </button>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary filetype">
                                                            <h3>PDF</h3>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary stix">
                                                            <h3>STIX</h3>
                                                        </button>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-secondary csv">
                                                            <h3>CSV</h3>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <span class="page-link">Previous</span>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item active" aria-current="page">
                                            <span class="page-link">2</span>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>