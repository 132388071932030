import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  currPage = 0;
  isMalwareJobsLoadingError: boolean = false;
  isMalwareJobsLoading: boolean = true;
  malwareJobs: any[] = []; // Stores Malware Jobs from API
  pageSize = 10;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getMalwareJobs();
  }

  changePage(direction: number) {
    this.currPage += direction;
  }

  getDateDisplay(date: string): string {
    return new Date(date).toLocaleString();
  }

  getMalwareJobs() {
    const url = 'https://4xzvyw4dd8.execute-api.us-east-1.amazonaws.com/v1/malware/jobs';

    this.http.get(url).subscribe({
      next: (response: any) => {
        this.malwareJobs = response["Jobs"]; // Store the API response data
        console.log(response);

        let analysisPaginated = [];
        // for (let i = 0; i < this.malwareJobs.length; i++) {
        //   console.log(this.malwareJobs[i]["filename"]);
        //   this.malwareJobs[i]["filename"] = this.malwareJobs[i]["filename"].replace("%20", " ");
        //   console.log(this.malwareJobs[i]["filename"]);
        // }

        while (this.malwareJobs.length > 0)
          analysisPaginated.push(this.malwareJobs.splice(0, this.pageSize));

        this.malwareJobs = analysisPaginated;
        console.debug(this.malwareJobs);
      },
      error: (error: any) => {
        console.error(error); // Handle any errors that occur during the API call
        this.isMalwareJobsLoadingError = true;
      },
      complete: () => {
        console.log('API call completed'); // Handle the completion of the API call if needed
        this.isMalwareJobsLoading = false;
      }
    });
  }
}


