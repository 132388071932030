import { Component } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { S3ServiceService } from 'app/service/s3/s3-service.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  color = 'accent';
  endpoint = '';
  fileToUpload: File | null = null;
  isOverwrite: boolean | undefined;
  isSubmissionSuccessful: boolean = false;
  isSubmitting: boolean = false;
  isVTChecked = false;
  isCreateReportChecked = false;
  submissionStatus = '';
  fileList: File[] = [];
  
  constructor(
    private s3ServiceService: S3ServiceService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  getEndpointDisplay(): string {
    if (this.endpoint == 'bmachine') return 'Legacy B Machine';

    if (this.endpoint == 'stepFunctions') return 'Gemini Step Functions';

    return 'Please Select';
  }

  getOverwriteDisplay(): string {
    if (this.isOverwrite == undefined) return 'Please Select';
    if (this.isOverwrite) return 'Yes, overwrite';
    else return 'No, do not overwrite';
  }

  onFilesSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files) {
      // Add each selected file to the fileList array
      for (let i = 0; i < inputElement.files.length; i++) {
        this.fileList.push(inputElement.files[i]);
      }
    }
  }

  uploadMalware(): void {
    this.isSubmitting = true;
    this.cd.detectChanges(); // Manually trigger change detection

    if (this.fileList.length > 0) {
      this.s3ServiceService
        .uploadFiles(this.fileList, this.isVTChecked)
        .subscribe({
          next: (v) => {
            this.isSubmissionSuccessful = true;
            // Redirect to the "Results" page on successful upload
            this.router.navigate(['results']);
          },
          error: (e) => {
            console.error('Error uploading files:', e);
          },
          complete: () => {
            this.isSubmitting = false;
            this.fileList = [];
            this.cd.detectChanges(); // Manually trigger change detection
          },
        });
    }
  }

  removeFile(file: File): void {
    const index = this.fileList.indexOf(file);
    if (index !== -1) {
      this.fileList.splice(index, 1);
    }
  }

  onFileDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  onFileDropped(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer) {
      const files = event.dataTransfer.files;

      if (files && files.length > 0) {
        this.fileList = Array.from(files); // Convert FileList to an array
      }
    }
  }
}
