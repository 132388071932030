import { Component } from '@angular/core';

@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss']
})
export class ObjectsComponent {

  activeTab: string = 'domains'; // Set 'domains' as the default active tab


  showTable: boolean = false;
  showTable2: boolean = false;

  toggleShowTable(): void {
    this.showTable = !this.showTable;
}

  toggleShowTable2(): void {
    this.showTable2 = !this.showTable2;
}



}
