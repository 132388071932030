<div class="router-section">
    <div class="container-fluid m-5">
        <h1 *ngIf="isLoading" class="text-center w-100 mt-5">
            Loading results for {{ filename }}
            <div class="spinner-border text-blue" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </h1>
        <div *ngIf="!isLoading && !isError">
            <h2 class="text-center">VirusTotal Results for {{ filename }}</h2>
            <div class="row m-5">
                <div class="col-md-4">
                    <div class="card">
                        <h2 class="text-center mt-3">Verdict</h2>
                        <hr>
                        <div class="card-body">
                            <div class="card-title text-capitalize">
                                <h3 class="text-center">

                                    <i class="bi bi-check-circle-fill text-success"
                                        *ngIf="analysis['data.attributes.threat_verdict'] === 'clean'"></i>
                                    <i class="bi bi-exclamation-triangle text-warning"
                                        *ngIf="analysis['data.attributes.threat_verdict'] === 'VERDICT_SUSPICIOUS'"></i>
                                    <i class="bi bi-radioactive text-danger"
                                        *ngIf="analysis['data.attributes.threat_verdict'] === 'VERDICT_MALICIOUS'"></i>
                                    <i class="bi bi-question-diamond text-secondary"
                                        *ngIf="analysis['data.attributes.threat_verdict'] === 'VERDICT_UNDETECTED' || analysis['data.attributes.threat_verdict'] === 'VERDICT_UNKNOWN'"></i>
                                    {{ analysis["data.attributes.threat_verdict"] }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <h2 class="text-center mt-3">Job Details</h2>
                        <hr>
                        <div class="card-body">
                            <div class="row ">
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3 ">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            style="margin-right: -10px;" id="analysisSampleMeaningfulName"
                                            [value]="analysis['data.attributes.meaningful_name']">
                                        <label class="detail-label" for="analysisSampleMeaningfulName">Meaningful
                                            Name</label>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly
                                            class="form-control-plaintext detail-input text-capitalize fs-5"
                                            id="analysisSampleZenboxConfidence"
                                            [value]="analysis['data.attributes.sandbox_verdicts.Zenbox.confidence']">
                                        <label class="detail-label" for="analysisSampleZenboxConfidence">Zenbox
                                            Confidence</label>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly
                                            class="form-control-plaintext detail-input text-capitalize fs-5"
                                            id="analysisSampleZenboxVerdict"
                                            [value]="analysis['data.attributes.sandbox_verdicts.Zenbox.category']">
                                        <label class="detail-label" for="analysisSampleZenboxVerdict">Zenbox
                                            Verdict</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleTypeDesc"
                                            [value]="analysis['data.attributes.type_description']">
                                        <label class="detail-label" for="analysisSampleTypeDesc">File Type
                                            Description</label>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleTypeEx"
                                            [value]="analysis['data.attributes.type_extension']">
                                        <label class="detail-label" for="analysisSampleTypeEx">Type Extension</label>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly
                                            class="form-control-plaintext detail-input fs-5 extend-width"
                                            id="analysisSampleSigInfoDesc"
                                            [value]="analysis['data.attributes.signature_info.description']">
                                        <label class="detail-label" for="analysisSampleSigInfoDesc">Signature Info
                                            Description</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleThreatLvlSec"
                                            [value]="analysis['data.attributes.threat_severity.threat_severity_level']">
                                        <label class="detail-label" for="analysisSampleThreatLvlSec">Threat Security
                                            Level</label>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleThreatLevelSevDesc"
                                            [value]="analysis['data.attributes.threat_severity.level_description']">
                                        <label class="detail-label" for="analysisSampleThreatLevelSevDesc">Threat
                                            Severity
                                            Level Description</label>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleSigInfoComments"
                                            [value]="analysis['data.attributes.signature_info.comments']">
                                        <label class="detail-label" for="analysisSampleSigInfoComments">Signature Info
                                            Comments</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleMd5" [value]="analysis['data.attributes.md5']">
                                        <label class="detail-label" for="analysisSampleMd5">MD5</label>
                                    </div>

                                </div>
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleSha1" [value]="analysis['data.attributes.sha1']">
                                        <label class="detail-label" for="analysisSampleSha1">SHA1</label>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleSsdeep" [value]="analysis['data.attributes.ssdeep']">
                                        <label class="detail-label" for="analysisSampleSsdeep">SSDEEP</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input type="text" readonly class="form-control-plaintext detail-input fs-5"
                                            id="analysisSampleSha256" [value]="analysis['data.attributes.sha256']">
                                        <label class="detail-label" for="analysisSampleSha256">SHA256</label>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div class="text-end mt-3">
                        <a class="btn btn-primary download-results-button me-2" (click)="downloadRawResults()"
                            [href]="downloadResultsHref" [download]="filename + '-virustotal.json'">
                            <i class="bi bi-download"></i>&nbsp;
                            Download Results (.json)
                        </a>
                        <button class="btn btn-primary download-results-button" disabled="true">
                            <span>
                                <i class="bi bi-file-earmark-richtext"></i>&nbsp;
                                Download PDF
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isError">
            <div class="alert alert-danger" role="alert">
                An error occured - please reach out to the Gemini Administrators
            </div>
        </div>
    </div>
</div>