import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './service/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean;
  title = 'Gemini';
  
  constructor(
    private cognitoService: AuthenticationService) {
    this.isAuthenticated = false;
  }

  ngOnInit(): void {
    this.isAuthenticated = this.cognitoService.isAuthenticated;
  }
}