<div class="router-section">
  <div class="container-fluid">
    <div class="row m-5 mb-2">
      <div class="title">
        <h1>Malware Hunt Search</h1>
      </div>
      <div class="col-md-8 offset-md-1">
        <div class="card1 mt-3 p-4">
          <div class="input-group">
            <input class="form-control" type="text" [(ngModel)]="q" placeholder="Enter search term"
              (input)="onInputChange()" (keyup.enter)="search()" />
            <span class="input-group-append">
              <button type="button" class="btn search" [class.search-active]="isSearchTermEntered" (click)="search()"
                [disabled]="!q">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-search"
                  viewBox="0 0 16 16">
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </span>
          </div>
        </div>

        <div class="row m-5 mb-2">
          <div *ngIf="isLoading" class="align-center">
            <h3>
              Loading
              <div class="spinner-border text-navy-blue navy-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </h3>
          </div>
        </div>

        <div class="row m-2 mb-2">
          <div *ngIf="!isLoading && hits.length == 0 && q && q == prevSearchTerms" class="align-center">
            <h3>
              No Results found
            </h3>
          </div>
        </div>
        <div *ngIf="!isLoading && paginatedHits.length > 0" id="hits-container">
          <div class="card2 mt-2 p-1">
            <p>Total Records: <b>{{ totalCount }}</b></p>
          </div>
          <div class="card3 mt-2 p-1">
            <div *ngFor="let hit of paginatedHits" class="hits">
              <!-- Display paginated hits -->
              <div class="row">
                <div class="col-lg-2 fileName">
                  <pre><b>{{ hit.name }}</b></pre>
                </div>
                <div class="col">
                  <a [href]="hit.downloadUrl" target="_blank">
                    {{ hit.downloadTitle }}
                  </a>
                  <pre class="searchdata"><b>SHA256:</b> {{hit.malware_sha256}}</pre>
                  <pre class="searchdata"><b>Created at:</b> {{hit.createdAt}}</pre>
                </div>
                <div class="col-lg-2 fileDetails">
                  <button *ngIf="hit.details" type="button" (click)="details(hit)" class="btn btn-secondary">
                    Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col">
            <!-- Pagination controls -->
            <div *ngIf="hits.length > 0">
              <ul class="pagination justify-content-end">
                <li class="page-item" *ngIf="currentPage > 1" (click)="changePage(currentPage - 1)">
                  <button type="button" class="page-link">Previous</button>
                </li>
                <li class="page-item" *ngFor="let page of pages" (click)="changePage(page)">
                  <button type="button" class="page-link">Page {{ page }}</button>
                </li>
                <li class="page-item" *ngIf="currentPage < pages.length" (click)="changePage(currentPage + 1)">
                  <button type="button" class="page-link">Next</button>
                </li>
                <li class="page-item" *ngIf="currentPage * itemsPerPage < hits.length"
                  (click)="changePage(calculateLastPage())">
                  <button type="button" class="page-link">End</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>