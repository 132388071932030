<div class="router-section">
  <div class="row min-vh-100 justify-content-center align-items-center no-gutters">
    <div class="col-6 text-center">
      <div class="login-section">
        <img src="./assets/Gemini-Logo-White.png" class="img-fluid homepage-icon" width="350" height="350">

        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="!isLoading && errorMessage">
          {{ errorMessage }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <div class="input-group pb-4">
          <span class="input-group-text">
            Username
          </span>
          <input type="text" class="form-control" [(ngModel)]="email" required (keyup.enter)="onEnterKey()" />
        </div>

        <div class="input-group pb-4">
          <span class="input-group-text">
            {{ isPasswordReset ? "New" : ""}} Password
          </span>
          <input type="password" class="form-control" [(ngModel)]="password" required (keyup.enter)="onEnterKey()" />
        </div>

        <div class="input-group" *ngIf="isPasswordReset">
          <span class="input-group-text">
            Repeat Password
          </span>
          <input type="password" class="form-control" [(ngModel)]="repeatPassword" required>
        </div>

        <button class="btn btn-lg btn-success mt-3" type="button" (click)="onSignIn()"
          [disabled]="isLoading || !email || !password" *ngIf="!isPasswordReset">
          <span *ngIf="!isLoading">Login</span>
          <span *ngIf="isLoading">
            <div class="spinner-border text-white" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
        </button>

        <button class="btn btn-lg btn-primary mt-3" type="button" (click)="setNewPassword()"
          [disabled]="isLoading || !email || !password || !repeatPassword || password != repeatPassword"
          *ngIf="isPasswordReset">
          <span *ngIf="!isLoading">Reset Password</span>
          <span *ngIf="isLoading">
            <div class="spinner-border text-white" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>