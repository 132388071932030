<div class="router-section">
  <div class="container-fluid">
    <div class="row m-5 mb-2">
      <div class="title">
        <h1>Vulnerability</h1>
      </div>

    </div>
    <div class="col-md-4 offset-md-2">
      <div class="card1 mt-2 p-4">
        <!--<h4 class="card-title mt-0">Vulnerability Data Information</h4>-->
        <div class="input-group mt-2">
          <span class="input-group-append">
            <button type="button" class="btn search">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </span>
          <input class="form-control" type="text" [(ngModel)]="keyword" placeholder="Enter keyword"
            (keyup.enter)="postVulnDB()">

        </div>
        <div class="card-body">
          <h6>Search for vendors and products; narrow your search with a date parameter</h6>
          <h5>Choose a date:</h5>
          <label for="start_date">
            <h6>Start Date:</h6>
          </label>
          <input type="date" style="height: 30px;" id="start_date" [(ngModel)]="start_date"
            (keyup.enter)="postVulnDB()">
          <label class="end_date" for="end_date">
            <h6>End Date:</h6>
          </label>
          <input type="date" style="height: 30px;" id="end_date" [(ngModel)]="end_date" (keyup.enter)="postVulnDB()">
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <button (click)="postVulnDB()" [disabled]="!keyword" class="btn btn-primary submit">Search</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 offset-md-2"> <!-- Adjust the column width as per your layout -->
    <div class="container">
      <ng-container *ngIf="isVulnDBLoading" class="loading-container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="row m-5 mb-2">
              <div class="col-12">
                <table class="table-results">
                  <tbody>
                    <tr>
                      <td class="align-center">
                        <h3>
                          Loading&nbsp;
                          <div class="spinner-border text-navy-blue navy-spinner" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="container-vuln">
    <div class="row justify-content-center">
      <div class="col-10 offset-md-1 text-center">
        <div class="row m-5 mb-1">
          <table class="table">
            <tbody *ngIf="vulnDB.length > 0 && !isVulnDBLoading">
              <ng-container *ngFor="let obj of vulnDB[currPage]">
                <tr class="result">
                  <td class="result-cell">
                    <!--
              <tr class="result" *ngFor="let obj of vulnDB | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage">
                -->

                    <p>ID: {{ obj.vulndb_id }}</p>
                  </td>
                  <td class="result-cell">
                    <!-- Display obj.keywords if available, otherwise display obj.title -->
                    <ng-container *ngIf="obj.title; else keywordsFallback">{{ obj.title }}</ng-container>
                    <ng-template #keywordsFallback>{{ obj.keywords }}</ng-template>
                  </td>
                  <td class="result-cell">
                    <button class="btn btn-md btn-json" (click)="downloadObjectAsJson(obj)">
                      <i class="bi bi-download"></i>&nbsp;Download (.json)
                    </button>
                  </td>
                  <td class="result-cell">
                    <button class="btn btn-md btn-pdf" (click)="downloadPdf(obj.vulndb_id)">
                      <i class="bi bi-file-earmark-richtext"></i>&nbsp;Download PDF
                    </button>
                  </td>
                  <td class="result-cell">
                    <button class="btn btn-md btn-browser" (click)="showJsonInBrowser(obj.vulndb_id)">
                      <i class="bi bi-eye-fill"></i>&nbsp;See in browser
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tbody *ngIf="isVulnDBLoadingError">
              <tr>
                <td colspan="4">
                  <h3>No results found</h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-10 offset-md-1">
        <ul class="pagination justify-content-end" *ngIf="vulnDB.length > 0">
          <li class="page-item" (click)="changePage(1 - currPage)">
            <button type="button" class="page-link" [disabled]="currPage == 0">
              First
            </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" (click)="changePage(-1)" [disabled]="currPage == 0">
              Previous
            </button>
          </li>
          <li class="page-item">
            <span class="page-link">Page: {{ currPage + 1 }}</span>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" (click)="changePage(1)" [disabled]="currPage + 1 == vulnDB.length">
              Next
            </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" (click)="changePage(vulnDB.length - currPage)"
              [disabled]="currPage + 1 == vulnDB.length">
              End
            </button>
          </li>
        </ul>
      </div>


    </div>