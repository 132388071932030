import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { environment } from "../../../environments/environment";

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUser: CognitoUser | null = null;
  private poolData = {
    UserPoolId: environment.cognito.UserPoolId,
    ClientId: environment.cognito.ClientId
  };
  private sessionUserAttributes = null;

  constructor() { }

  handleNewPassword(newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.currentUser?.completeNewPasswordChallenge(newPassword, this.sessionUserAttributes, {
        onSuccess: (session) => {
          console.debug("New Password Set!");
          resolve(session);
          return;
        },
        onFailure: (err) => {
          reject(err.message || JSON.stringify(err));
          return;
        }
      })
    })
  }

  get isAuthenticated(): boolean {
    let isAuth = false;
    let userPool = new CognitoUserPool(this.poolData);
    let cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          console.error(err.message || JSON.stringify(err));
        }
        this.currentUser = cognitoUser;
        isAuth = session.isValid();
      })
    }

    return isAuth;
    // return true;
  }

  login(email: string, password: string): Promise<any> {
    let authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });
    let userPool = new CognitoUserPool(this.poolData);
    let userData = { Username: email, Pool: userPool };
    let cognitoUser = new CognitoUser(userData);

    this.currentUser = cognitoUser;
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (_) => {
          console.debug("Authenticated!");
          resolve(authenticationDetails);
          return;
        },
        onFailure: (err) => {
          reject(err.message || JSON.stringify(err));
          return;
        },
        newPasswordRequired: (userAttributes) => {
          console.debug("User created by Admin, must set new password");
          delete userAttributes.email_verified;
          delete userAttributes.email;
          this.sessionUserAttributes = userAttributes;
          reject("SET_NEW_PASSWORD");
          return;
        },
      });
    });
  }

  logout() {
    this.currentUser?.signOut();
    this.currentUser = null;
  }
}
