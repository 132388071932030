import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';

type SearchResult = {
  name: string;
  details: string[] | null;
  downloadUrl: string;
  downloadTitle: string;
  // TODO change this from any to the correct value
  createdAt: any,
  malware_sha256: string,
};

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  isLoading = false;
  currentPage = 1;
  itemsPerPage = 25;
  // User input keyword
  q: string = '';
  isSearchTermEntered: boolean = false;
  // Used to prevent reloading of the same data
  prevSearchTerms: string = '';

  hits: SearchResult[] = [];
  totalCount = 0;

  baseUrl = environment.ApiUrl;

  // Methods for pagination
  get paginatedHits(): SearchResult[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.hits.slice(startIndex, endIndex);
  }


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    // TODO might be able to remap this to @Input() instead of the long command
    this.route.paramMap.subscribe((params) => {
      this.q = params.get('query') || '';

      this.prevSearchTerms = this.q;
      this.queryOpenSearch();
    });
  }

  search() {
    // Exit if the user didn't enter any valid data
    if (!this.q || this.q.trim() === '' || this.q == this.prevSearchTerms) {
      return;
    }

    this.hits = [];

    // Remove any whitespaces from the front and back of the query result
    this.q = this.q.trim();

    // Navigate to the same page to save to browser history, and enable sharing
    this.router.navigate(['/search', this.q]);
  }
  onInputChange() {
    // Check if the input value is not empty
    this.isSearchTermEntered = this.q.trim() !== '';
  }
  queryOpenSearch() {
    // Exit if the user didn't enter any valid data
    if (!this.q || this.q.trim() === '') {
      return;
    }
    // Remove any whitespaces from the front and back of the query result
    this.q = this.q.trim();

    this.isLoading = true;

    let url = `${this.baseUrl}/malware/search?q=${encodeURIComponent(this.q)}`;

    // TODO if we are only use the query parameter might need to switch to get
    this.http.post(url, null).subscribe({
      next: (res: any) => {
        let temp = res?.hits?.hits || [];

        this.totalCount = res?.totalCount || 0;

        if (temp) {
          temp = temp.map((val: any) => {
            return this.formatResults(val);
          });
        }

        this.hits = temp;
      },
      error: (error: any) => {
        console.error(error);
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  details(hit: SearchResult) {
    if (hit.details) {
      this.router.navigate(hit.details);
    }
  }

  formatResults(item: any) {
    let folderStructure = item._source.fileName.split('/');

    let obj: SearchResult = {
      name: '',
      createdAt: item._source.createdAt,
      malware_sha256: item._source.malware_sha256,
      details: [],
      downloadUrl: '',
      downloadTitle: folderStructure[2],
    };

    switch (folderStructure[1]) {
      case 'vmray': {
        obj.name = 'VMRay';
        obj.details = [
          'results/detail/vmray/',
          folderStructure[2].replace(
            '.json',
            ''
          ),
          folderStructure[2],
        ];
        obj.downloadUrl = `${this.baseUrl}/malware/analysis/vmray/${folderStructure[2]}`.replace(
          '.json',
          ''
        );
        break;
      }
      case 'virus-total': {
        obj.name = 'Virus Total';
        obj.details = [
          'results/detail/vt/',
          folderStructure[2].replace(
            '.json',
            ''
          ),
          folderStructure[2],
        ];
        obj.downloadUrl = `${this.baseUrl}/malware/analysis/vt/${folderStructure[2]}`.replace(
          '.json',
          ''
        );
        break;
      }
      case 'vmray-stix-2-1': {
        obj.name = 'VMRay STIX (2.1)';
        // TODO figure out the details for vmray
        obj.details = null;
        obj.downloadUrl =
          `${this.baseUrl}/malware/stix-2-1/vmray/${folderStructure[2]}`.replace(
            '.json',
            ''
          );
        break;
      }
      default:
        console.log('An unknown value was passed in: ' + folderStructure[1]);
    }

    return obj;
  }

  changePage(pageNumber: number) {
    this.currentPage = pageNumber;
  }
  calculateLastPage(): number {
    return Math.ceil(this.hits.length / this.itemsPerPage);
  }
  get pages(): number[] {
    if (this.hits.length <= this.itemsPerPage) {
      return [1];
    } else {
      const pageCount = Math.ceil(this.hits.length / this.itemsPerPage);

      // Show only the current page and its adjacent pages
      const startPage = Math.max(1, this.currentPage - 1);
      const endPage = Math.min(pageCount, this.currentPage + 1);

      return new Array(endPage - startPage + 1).fill(0).map((_, index) => startPage + index);
    }
  }
}
